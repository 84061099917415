.paddign-10 {
  padding: 10px;
}

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  padding: 5px;
  background-color: #ffffff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
}
.p_wrap {
  white-space: pre-line;
  text-align: left;
  margin-top: -20px;
  font-size: small;
}
.recht {
  float: right;
}
.links {
  float: left;
}

.textarea.form-control {
  max-height: 100%;
}

h3 #notification-dirct-job {
  font-size: 20px;
}

/* .sidebar .nav p,
.off-canvas-sidebar .nav #testamen {
  background-color: green;
} */

/* carousel */

.carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  color: transparent;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  font-size: 50px;
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: transparent;
}

.carousel-indicators li {
  background-color: #f00;
}

.carousel .carousel-inner {
  box-shadow: unset;
}

.section-top-minus-40 {
  margin-top: -40px;
}

.customCard {
  margin-top: -40px;
  background: #f1f1f1;
}

.ex1 {
  margin-top: -10px;
}

.ex2 {
  margin-top: -2px;
}