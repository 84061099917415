/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box; }
  @media only screen and (max-width: 56.25em) {
    body {
      padding: 0; } }

::selection {
  background-color: #55c57a;
  color: #fff; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #777; }

.body1{

  padding: 3rem;

  }

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem; }
  .heading-primary--main {
    position: relative;
    top: 2rem;
    display: block;
    font-size: 4rem;
    font-weight: 400;
    letter-spacing: 2rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */ }
    @media only screen and (max-width: 37.5em) {
      .body1{

        padding: 0;
      
        }
      .heading-primary--main {
        letter-spacing: 1rem;
        font-family: 5rem;
        font-size: 3rem;
        top: 4rem; } }
  .heading-primary--sub {
    position: relative;
    top: 2rem;
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.5rem;
    animation: moveInRight 1s ease-out; }
    .heading-primary--sub1 {
      position: relative;
      top: 2rem;
      display: block;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 0.5rem;
      }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--sub {
        letter-spacing: .5rem;
        font-size: 1.4rem;
        top: 5rem; }
        .heading-primary--sub1 {
          letter-spacing: .5rem;
          font-size: 2rem;
          top: 0; }
      }

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary {
      font-size: 2.5rem; } }
  .heading-secondary:hover {
    
    text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2); }

    .heading-secondary1 {
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      background-image: linear-gradient(to right, #7ed56f, #28b485);
      -webkit-background-clip: text;
      color: transparent;
      letter-spacing: .2rem;
      margin-top: 13rem;
    }
      @media only screen and (max-width: 56.25em) {
        .heading-secondary1 {
          font-size: 3rem; 
          margin-top: 9rem;
        } }
      @media only screen and (max-width: 37.5em) {
        .heading-secondary1 {
          font-size: 2.5rem;
          margin-top: 8rem;
        } }
      .heading-secondary1:hover {
        text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2); }

.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase; }

.paragraph {
  font-size: 1.6rem; }
  .paragraph:not(:last-child) {
    margin-bottom: 3rem; }

.timeline_img {
  position: relative;
  left: 0;
  top: -100px;
  z-index: 1; }
  @media only screen and (max-width: 37.5em) {
    .timeline_img {
      top: -45px; } }
  @media only screen and (max-width: 56.25em) {
    .timeline_img {
      top: -45px; } }

.u-center-text {
  text-align: center !important; }

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-bottom-medium {
      margin-bottom: 3rem !important; } }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-bottom-big {
      margin-bottom: 5rem !important; } }

.u-margin-top-big {
  margin-top: 8rem !important; }

.u-margin-top-huge {
  margin-top: 10rem !important; }

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .15;
  overflow: hidden; }
  .bg-video__content {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.btn1, .btn1:link, .btn1:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  cursor: pointer; }

.btn1:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn1:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.btn1:active, .btn1:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn1--white {
  background-color: #fff;
  color: #777; }
  .btn1--white::after {
    background-color: #fff; }

.btn1--green {
  background-color: #55c57a;
  color: #fff; }
  .btn1--green::after {
    background-color: #55c57a; }

.btn1::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }

.btn1--animated {
  animation: moveInBottom .5s ease-out .75s;
  animation-fill-mode: backwards; }

.btn1-text:link, .btn1-text:visited {
  font-size: 1.6rem;
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  padding: 3px;
  transition: all .2s; }

.btn1-text:hover {
  background-color: #55c57a;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

/* .card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 78rem; }
  .card__side {
    height: 78rem;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
    .card__side--front {
      background-color: #fff; }
    .card__side--back {
      transform: rotateY(180deg); }
      .card__side--back-1 {
        background-image: linear-gradient(to right bottom, #ffb900, #ff7730); }
      .card__side--back-2 {
        background-image: linear-gradient(to right bottom, #7ed56f, #28b485); }
      .card__side--back-3 {
        background-image: linear-gradient(to right bottom, #2998ff, #5643fa); }
  .card:hover .card__side--front {
    transform: rotateY(-180deg); }
  .card:hover .card__side--back {
    transform: rotateY(0); }
  .card__picture {
    background-size: cover;
    height: 23rem;
    background-blend-mode: screen;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .card__picture--1 {
      background-image: linear-gradient(to right bottom, #ffb900, #ff7730), url(../img/nat-5.jpg); }
    .card__picture--2 {
      background-image: linear-gradient(to right bottom, #7ed56f, #28b485), url(../img/nat-6.jpg); }
    .card__picture--3 {
      background-image: linear-gradient(to right bottom, #2998ff, #5643fa), url(../img/nat-7.jpg); }
  .card__heading {
    font-size: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: #fff;
    position: absolute;
    top: 12rem;
    right: 2rem;
    width: 75%; }
  .card__heading-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    .card__heading-span--1 {
      background-image: linear-gradient(to right bottom, rgba(255, 185, 0, 0.85), rgba(255, 119, 48, 0.85)); }
    .card__heading-span--2 {
      background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.85), rgba(40, 180, 133, 0.85)); }
    .card__heading-span--3 {
      background-image: linear-gradient(to right bottom, rgba(41, 152, 255, 0.85), rgba(86, 67, 250, 0.85)); }
  .card__details {
    padding: 3rem; }
    .card__details ul {
      list-style: none;
      width: 80%;
      margin: 0 auto; }
      .card__details ul li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem; }
        .card__details ul li:not(:last-child) {
          border-bottom: 1px solid #eee; }
  .card__cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center; }
  .card__price-box {
    text-align: center;
    color: #fff;
    margin-bottom: 8rem; }
  .card__price-only {
    font-size: 1.4rem;
    text-transform: uppercase; }
  .card__price-value {
    font-size: 6rem;
    font-weight: 100; }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .card {
      height: auto;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .card__side {
        height: auto;
        position: relative;
        box-shadow: none; }
        .card__side--back {
          transform: rotateY(0);
          clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%); }
      .card:hover .card__side--front {
        transform: rotateY(0); }
      .card__details {
        padding: 1rem 3rem; }
      .card__cta {
        position: relative;
        top: 0%;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 7rem 4rem 4rem 4rem; }
      .card__price-box {
        margin-bottom: 3rem; }
      .card__price-value {
        font-size: 4rem; } } */

.composition {
  position: relative; }
  .composition__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    transition: all .2s;
    outline-offset: 2rem; }
    @media only screen and (max-width: 56.25em) {
      .composition__photo {
        float: left;
        position: relative;
        width: 33.33333333%;
        box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2); } }
    .composition__photo--p1 {
      left: 0;
      top: -2rem; }
      @media only screen and (max-width: 56.25em) {
        .composition__photo--p1 {
          top: 0;
          transform: scale(1.2); } }
    .composition__photo--p2 {
      right: 0;
      top: 2rem; }
      @media only screen and (max-width: 56.25em) {
        .composition__photo--p2 {
          top: -1rem;
          transform: scale(1.3);
          z-index: 100; } }
    .composition__photo--p3 {
      left: 20%;
      top: 10rem; }
      @media only screen and (max-width: 56.25em) {
        .composition__photo--p3 {
          top: 1rem;
          left: 0;
          transform: scale(1.1); } }
    .composition__photo:hover {
      outline: 1.5rem solid #55c57a;
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
      z-index: 20; }
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95); }

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform .3s; }
  @media only screen and (max-width: 56.25em) {
    .feature-box {
      padding: 2rem; } }
  .feature-box__icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #7ed56f, #28b485);
    -webkit-background-clip: text;
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .feature-box__icon {
        margin-bottom: 0; } }
  .feature-box:hover {
    transform: translateY(-1.5rem) scale(1.03); }

.form__group:not(:last-child) {
  margin-bottom: 2rem; }

.form__input {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 1.5rem 2rem;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all .3s; }
  @media only screen and (max-width: 56.25em) {
    .form__input {
      width: 100%; } }
  .form__input:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid #55c57a; }
  .form__input:focus:invalid {
    border-bottom: 3px solid #ff7730; }
  .form__input::-webkit-input-placeholder {
    color: #999; }

.form__label {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: .7rem;
  display: block;
  transition: all .3s; }

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem); }

.form__select {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 1.5rem 1rem;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all .3s; }
  @media only screen and (max-width: 56.25em) {
    .form__select {
      width: 100%; } }
  .form__select:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border-bottom: 3px solid #55c57a; }
  .form__select:focus:invalid {
    border-bottom: 3px solid #ff7730; }
  .form__select::-webkit-input-placeholder {
    color: #999; }

.form__select:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem); }

.form__radio-group {
  width: 49%;
  display: inline-block; }
  @media only screen and (max-width: 56.25em) {
    .form__radio-group {
      width: 100%;
      margin-bottom: 2rem; } }

.form__radio-input {
  display: none; }

.form__radio-label {
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  padding-left: 4.5rem; }

.form__radio-button {
  height: 3rem;
  width: 3rem;
  border: 5px solid #55c57a;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -.4rem; }
  .form__radio-button::after {
    content: "";
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #55c57a;
    opacity: 0;
    transition: opacity .2s; }

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1; }

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .popup {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.3); } }
  .popup__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    background-color: #fff;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: table;
    overflow: hidden;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.25);
    transition: all .5s .2s; }
  .popup__left {
    width: 33.333333%;
    display: table-cell; }
  .popup__right {
    width: 66.6666667%;
    display: table-cell;
    vertical-align: middle;
    padding: 3rem 5rem; }
  .popup__img {
    display: block;
    width: 100%; }
  .popup__text {
    font-size: 1.4rem;
    margin-bottom: 4rem;
    -moz-column-count: 2;
    -moz-column-gap: 4rem;
    -moz-column-rule: 1px solid #eee;
    column-count: 2;
    column-gap: 4rem;
    column-rule: 1px solid #eee;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto; }
  .popup:target {
    opacity: 1;
    visibility: visible; }
  .popup:target .popup__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }
  .popup__close:link, .popup__close:visited {
    color: #777;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    text-decoration: none;
    display: inline-block;
    transition: all .2s;
    line-height: 1; }
  .popup__close:hover {
    color: #55c57a; }

.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  transform: skewX(-12deg); }
  @media only screen and (max-width: 56.25em) {
    .story {
      width: 100%;
      padding: 4rem;
      padding-left: 7rem; } }
  @media only screen and (max-width: 37.5em) {
    .story {
      transform: skewX(0); } }
  .story__shape {
    width: 15rem;
    height: 15rem;
    float: left;
    transform: translateX(-3rem) skewX(12deg);
    position: relative;
    overflow: hidden;
    border-radius: 50%; }
    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      .story__shape {
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        -webkit-shape-outside: circle(50% at 50% 50%);
        shape-outside: circle(50% at 50% 50%);
        border-radius: none; } }
    @media only screen and (max-width: 37.5em) {
      .story__shape {
        transform: translateX(-3rem) skewX(0); } }
  .story__img {
    height: 100%;
    margin-left: 35px;
    transform: translateX(-4rem) scale(1.4);
    backface-visibility: hidden;
    transition: all .5s; }
  .story__text {
    transform: skewX(12deg); }
    @media only screen and (max-width: 37.5em) {
      .story__text {
        transform: skewX(0); } }
  .story__caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.7rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden; }
  .story:hover .story__caption {
    opacity: 1;
    transform: translate(-50%, -50%); }
  .story:hover .story__img {
    transform: translateX(-4rem) scale(1);
    filter: blur(3px) brightness(80%); }


.footer1 {
  background-color: #333;
  padding: 10rem 0;
  font-size: 1.4rem;
  color: #f7f7f7; }
  @media only screen and (max-width: 56.25em) {
    .footer1 {
      padding: 8rem 0; } }
  .footer1__logo-box {
    text-align: center;
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .footer1__logo-box {
        margin-bottom: 6rem; } 
      
.footer1_image{
  width: 50%;
}
      }
  .footer1__logo {
    width: 15rem;
    height: auto; }
  .footer1__navigation {
    border-top: 1px solid #777;
    padding-top: 2rem;
    display: inline-block; }
    @media only screen and (max-width: 56.25em) {
      .footer1__navigation {
        width: 100%;
        text-align: center; } }
  .footer1__list {
    list-style: none; }
  .footer1__item {
    display: inline-block; }
    .footer1__item:not(:last-child) {
      margin-right: 1.5rem; }
  .footer1__link:link, .footer1__link:visited {
    color: #f7f7f7;
    background-color: #333;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    }
  .footer1__link:hover, .footer1__link:active {
    color: #55c57a;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
     }
  .footer1__copyright {
    border-top: 1px solid #777;
    padding-top: 2rem;
    width: 80%;
    float: right; }
    @media only screen and (max-width: 56.25em) {
      .footer1__copyright {
        width: 100%;
        float: none; } }

.row1 {
  max-width: 114rem;
  margin: 0 auto; }
  .row1:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .row1:not(:last-child) {
        margin-bottom: 6rem; } }
  @media only screen and (max-width: 56.25em) {
    .row1 {
      max-width: 50rem;
      padding: 0 3rem; } }
  .row1::after {
    content: "";
    display: table;
    clear: both; }
  .row1 [class^="col-"] {
    float: left; }
    .row1 [class^="col-"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .row1 [class^="col-"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .row1 [class^="col-"] {
        width: 100% !important; } }
  .row1 .col-1-of-2 {
    width: calc((100% - 6rem) / 2); }
  .row1 .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .row1 .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem); }
  .row1 .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4); }
  .row1 .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem); }
  .row1 .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem); }

.header {
  height: 85vh;
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.8), rgba(40, 180, 133, 0.8)), url(../img/hero-small.jpg);
  background-size: cover;
  background-position: top;
  position: relative; }
  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    .header {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
      height: 95vh; } }
  @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
    .header {
      background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.8), rgba(40, 180, 133, 0.8)), url(../img/hero.jpg); } }
  @media only screen and (max-width: 37.5em) {
    .header {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%); } }
  .header__logo-box {
    position: absolute;
    top: 4rem;
    left: 4rem; }
  .header__logo {
    height: 6rem; }
  .header__text-box {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }

.navigation__checkbox {
  display: none; }

.navigation__button {
  background-color: #fff;
  height: 7rem;
  width: 7rem;
  position: fixed;
  top: 6rem;
  right: 6rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer; }
  @media only screen and (max-width: 56.25em) {
    .navigation__button {
      top: 4rem;
      right: 4rem; } }
  @media only screen and (max-width: 37.5em) {
    .navigation__button {
      top: 3rem;
      right: 3rem; } }

.navigation__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background-image: radial-gradient(#7ed56f, #28b485);
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1); }
  @media only screen and (max-width: 56.25em) {
    .navigation__background {
      top: 4.5rem;
      right: 4.5rem; } }
  @media only screen and (max-width: 37.5em) {
    .navigation__background {
      top: 3.5rem;
      right: 3.5rem; } }

.navigation__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  @media only screen and (min-width: 112.5em) {
    .navigation__nav {
      left: -300px; } }
  @media only screen and (max-width: 37.5em) {
    .navigation__nav {
      left: -300px; } }

.navigation__list {
  position: absolute;
  top: 50%;
  left: 66%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  width: 100%; }
  @media only screen and (max-width: 56.25em) {
    .navigation__list {
      left: 60%; } }
  @media only screen and (max-width: 37.5em) {
    .navigation__list {
      left: 115%; } }

.navigation__item {
  margin: 1rem; }

.navigation__link:link, .navigation__link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
  background-size: 220%;
  transition: all .4s; }
  .navigation__link:link span, .navigation__link:visited span {
    margin-right: 1.5rem;
    display: inline-block; }

.navigation__link:hover, .navigation__link:active {
  background-position: 100%;
  color: #55c57a;
  transform: translateX(1rem); }

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80); }

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%; }

.navigation__icon {
  position: relative;
  margin-top: 3.5rem; }
  .navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 3rem;
    height: 2px;
    background-color: #333;
    display: inline-block; }
  .navigation__icon::before, .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all .2s; }
  .navigation__icon::before {
    top: -.8rem; }
  .navigation__icon::after {
    top: .8rem; }

.navigation__button:hover .navigation__icon::before {
  top: -1rem; }

.navigation__button:hover .navigation__icon::after {
  top: 1rem; }

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent; }

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg); }

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg); }

.section-about {
  background-color: #f7f7f7;
  padding: 25rem 0;
  margin-top: -20vh; }
  @media only screen and (max-width: 56.25em) {
    .section-about {
      padding: 20rem 0; } }

.section-features {
  padding: 20rem 0;
  background-image: linear-gradient(to right bottom, rgba(126, 213, 111, 0.8), rgba(40, 180, 133, 0.8)), url(../img/nat-4.jpg);
  background-size: cover;
  transform: skewY(-7deg);
  margin-top: -10rem; }
  .section-features > * {
    transform: skewY(7deg); }
  @media only screen and (max-width: 56.25em) {
    .section-features {
      padding: 10rem 0; } }

.section-tours {
  background-color: #f7f7f7;
  padding: 25rem 0 15rem 0;
  margin-top: -10rem; }
  @media only screen and (max-width: 56.25em) {
    .section-tours {
      padding: 20rem 0 10rem 0; } }

.section-stories {
  position: relative;
  padding: 15rem 0; }
  @media only screen and (max-width: 56.25em) {
    .section-stories {
      padding: 10rem 0; } }

.section-book {
  padding: 15rem 0;
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485); }
  @media only screen and (max-width: 56.25em) {
    .section-book {
      padding: 10rem 0; } }

.book {
  background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 50%, transparent 50%), url(../img/nat-10.jpg);
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2); }
  @media only screen and (max-width: 75em) {
    .book {
      background-image: linear-gradient(105deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 65%, transparent 65%), url(../img/nat-10.jpg);
      background-size: cover; } }
  @media only screen and (max-width: 56.25em) {
    .book {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), url(../img/nat-10.jpg); } }
  .book__form {
    width: 50%;
    padding: 6rem; }
    @media only screen and (max-width: 75em) {
      .book__form {
        width: 65%; } }
    @media only screen and (max-width: 56.25em) {
      .book__form {
        width: 100%; } }

.uk-section {
  background-color: #999; }

.owl-carousel {
  position: relative;
  margin-top: 30px; }

.candidate_home_select{
  width: 100%;
    /* height: 50%; */
    overflow: hidden;
    margin-bottom: 13px;
}

.owl-nav {
  position: absolute;
  top: -60px;
  left: 515px; }
  @media only screen and (max-width: 37.5em) {
    .owl-nav {
      display: none; }
      .candidate_home_select{
        width: 52%;
          /* height: 50%; */
          margin-left: 24%;
          margin-bottom: 13px;
          overflow: hidden;
      }
    }
  @media only screen and (max-width: 56.25em) {
    .owl-nav {
      display: none; } 
      
.candidate_home_select{
  width: 52%;
    /* height: 50%; */
    margin-left: 24%;
    margin-bottom: 13px;
    overflow: hidden;
}
    }
  @media only screen and (max-width: 75em) {
    .owl-nav {
      display: none; } }

.uk-card-primary {
  border-radius: 8px;
  background: #28b485 !important; }

h3 {
  margin-top: 10px; }

.uk-card > :last-child {
  margin-top: 0;
  margin-bottom: 10px; }

p {
  margin-top: 30px;
  margin-bottom: 0; }

.owl-next {
  background: #55c57a; }

.owl-theme .owl-nav [class*='owl-'] {
  background: #333; }

.owl-dots {
  margin-top: 30px; }

.uk-card-title {
  padding-bottom: 20px; }

.main-timeline {
  position: relative; }

.main-timeline:before {
  content: "";
  width: 5px;
  height: 100%;
  border-radius: 20px;
  margin: 0 auto;
  background: #242922;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.main-timeline .timeline {
  display: inline-block;
  margin-bottom: 50px;
  position: relative; }

.main-timeline .timeline:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  background: #ec496e;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%); }

.main-timeline .timeline-icon {
  display: inline-block;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 3px solid #ec496e;
  padding: 13px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateY(-50%); }

.main-timeline .timeline-icon i {
  display: block;
  border-radius: 50%;
  background: #ec496e;
  font-size: 64px;
  color: #fff;
  line-height: 100px;
  z-index: 1;
  position: relative; }

.main-timeline .timeline-icon:before,
.main-timeline .timeline-icon:after {
  content: "";
  width: 100px;
  height: 4px;
  background: #ec496e;
  position: absolute;
  top: 50%;
  right: -100px;
  transform: translateY(-50%); }

.main-timeline .timeline-icon:after {
  width: 70px;
  height: 50px;
  background: #f8f8f8;
  top: 89px;
  right: -30px; }

.main-timeline .timeline-content {
  width: 50%;
  padding: 0 50px 0;
  margin: 52px 0 0 0;
  float: right;
  position: relative; }

.main-timeline .timeline-content:before {
  content: "";
  width: 70%;
  height: 100%;
  border: 3px solid #ec496e;
  border-top: none;
  border-right: none;
  position: absolute;
  bottom: -22px;
  left: 35px; }

.main-timeline .timeline-content:after {
  content: "";
  width: 37px;
  height: 3px;
  background: #ec496e;
  position: absolute;
  top: 22px;
  left: 0; }

.main-timeline .title1 {
  font-size: 20px;
  font-weight: 600;
  color: #ec496e;
  text-transform: uppercase;
  margin: 0 0 5px 0; }

.main-timeline .description {
  display: inline-block;
  font-size: 16px;
  color: #404040;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0; }

.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: 30%; }

.main-timeline .timeline:nth-child(even) .timeline-icon:before {
  right: auto;
  left: -100px; }

.main-timeline .timeline:nth-child(even) .timeline-icon:after {
  right: auto;
  left: -30px; }

.main-timeline .timeline:nth-child(even) .timeline-content {
  float: left; }

.main-timeline .timeline:nth-child(even) .timeline-content:before {
  left: auto;
  right: 35px;
  transform: rotateY(180deg); }

.main-timeline .timeline:nth-child(even) .timeline-content:after {
  left: auto;
  right: 0; }

.main-timeline .timeline:nth-child(2n):before,
.main-timeline .timeline:nth-child(2n) .timeline-icon:before,
.main-timeline .timeline:nth-child(2n) .timeline-icon i,
.main-timeline .timeline:nth-child(2n) .timeline-content:after {
  background: #f9850f; }

.main-timeline .timeline:nth-child(2n) .timeline-icon {
  border-color: #f9850f; }

.main-timeline .timeline:nth-child(2n) .title1 {
  color: #f9850f; }

.main-timeline .timeline:nth-child(2n) .timeline-content:before {
  border-left-color: #f9850f;
  border-bottom-color: #f9850f; }

.main-timeline .timeline:nth-child(3n):before,
.main-timeline .timeline:nth-child(3n) .timeline-icon:before,
.main-timeline .timeline:nth-child(3n) .timeline-icon i,
.main-timeline .timeline:nth-child(3n) .timeline-content:after {
  background: #8fb800; }

.main-timeline .timeline:nth-child(3n) .timeline-icon {
  border-color: #8fb800; }

.main-timeline .timeline:nth-child(3n) .title1 {
  color: #8fb800; }

.main-timeline .timeline:nth-child(3n) .timeline-content:before {
  border-left-color: #8fb800;
  border-bottom-color: #8fb800; }

.main-timeline .timeline:nth-child(4n):before,
.main-timeline .timeline:nth-child(4n) .timeline-icon:before,
.main-timeline .timeline:nth-child(4n) .timeline-icon i,
.main-timeline .timeline:nth-child(4n) .timeline-content:after {
  background: #2fcea5; }

.main-timeline .timeline:nth-child(4n) .timeline-icon {
  border-color: #2fcea5; }

.main-timeline .timeline:nth-child(4n) .title1 {
  color: #2fcea5; }

.main-timeline .timeline:nth-child(4n) .timeline-content:before {
  border-left-color: #2fcea5;
  border-bottom-color: #2fcea5; }

@media only screen and (max-width: 1200px) {
  .main-timeline .timeline-icon:before {
    width: 50px;
    right: -50px; }
  .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    right: auto;
    left: -50px; }
  .main-timeline .timeline-content {
    margin-top: 75px; } }

@media only screen and (max-width: 990px) {
  .main-timeline .timeline {
    margin: 0 0 10px 0; }
  .main-timeline .timeline-icon {
    left: 25%; }
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    right: 25%; }
  .main-timeline .timeline-content {
    margin-top: 115px; } }

@media only screen and (max-width: 900px) {
  .main-timeline {
    padding-top: 50px; }
  .main-timeline:before {
    left: 80px;
    right: 0;
    margin: 0; }
  .main-timeline .timeline {
    margin-bottom: 70px; }
  .main-timeline .timeline:before {
    top: 0;
    left: 83px !important;
    right: 0;
    margin: 0; }
  .main-timeline .timeline-icon {
    width: 60px;
    height: 60px;
    line-height: 40px;
    padding: 5px;
    top: 0;
    left: 0; }
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: 0;
    right: auto; }
  .main-timeline .timeline-icon:before,
  .main-timeline .timeline:nth-child(even) .timeline-icon:before {
    width: 25px;
    left: auto;
    right: -25px; }
  .main-timeline .timeline-icon:after,
  .main-timeline .timeline:nth-child(even) .timeline-icon:after {
    width: 25px;
    height: 30px;
    top: 44px;
    left: auto;
    right: -5px; }
  .main-timeline .timeline-icon i {
    font-size: 30px;
    line-height: 45px; }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    width: 100%;
    margin-top: -24px;
    padding-left: 130px;
    padding-right: 5px; }
  .main-timeline .timeline:nth-child(even) .timeline-content {
    float: right; }
  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    width: 50%;
    left: 120px; }
  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    right: auto;
    transform: rotateY(0deg); }
  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(even) .timeline-content:after {
    left: 85px; } }

@media only screen and (max-width: 479px) {
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding-left: 110px; }
  .main-timeline .timeline-content:before,
  .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    left: 99px; }
  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(2n) .timeline-content:after {
    left: 65px; } }

.warpper {
  display: flex;
  flex-direction: column;
  align-items: center; }

.tab {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0px 2px;
  background: #28b485;
  display: inline-block;
  color: #fff;
  border-radius: 3px 3px 0px 0px;
  box-shadow: 0 0.5rem 0.8rem #00000080; }

.panels {
  background: #fffffff6;
  box-shadow: 0 2rem 2rem #00000080;
  min-height: 200px;
  width: 100%;
  max-width: 608px;
  border-radius: 3px;
  overflow: hidden;
  padding: 20px; }

.panel {
  display: none;
  animation: fadein .8s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.panel-title {
  font-size: 1.4em;
  font-weight: bold; }

.radio {
  display: none; }

#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel {
  display: block; }

#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab {
  background: #fffffff6;
  color: #000;
  border-top: 3px solid #000; }

.tab_package {
  overflow: hidden; }
  @media only screen and (max-width: 37.5em) {
    .tab_package {
      position: relative;
      right: 19%; } }

/* Style the buttons inside the tab */
.tab_package button {
  position: relative;
  left: 35%;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px; }

/* Change background color of buttons on hover */
.tab_package button:hover {
  background-color: #ddd; }

/* Create an active/current tablink class */
.tab_package button.active {
  background-color: #28b485;
  color: white; }

/* Style the tab content */
.tabcontent_package {
  display: none;
  border-top: none; }

  .premium_card {
    background-color: rgb(207, 255, 234);
     }
  
     .bordor_textaea {
      border-color: lightgray;
       }


       .titre-content:after,
       .titre-content:before{
         content:"";
         background-color:#28a745;
         position:absolute;
         top:0;
         bottom:0;
         width:36px;
         z-index:0;
       }
       .titre-content:before{
         transform-origin : 0 0;
         left:0;
         transform : skew(0, 20deg); 
       }
       .titre-content:after {
         transform-origin : 100% 0;
         transform : skew(0, 20deg); 
         right:0;
       }
       .titre-content { 
        bottom: 48px;
        left: -9px;
         transform : rotate(-2deg); 
         position:relative;
         max-width:100%;
         font-size:8px;
         color:#fff; 
         text-align:center;
         /* J'ai mis 50px au pif */
         margin :50px auto 50px auto; 
         font-family : Verdana, Geneva, sans-serif;
       }
       .titre-content div {
         background:#6bd098;
         position:relative;
         z-index:3;
         padding:10px;
       }
       .titre-content h1,
       .titre-content h2{
         padding:0;
         margin:0;
         transform : rotate(2deg); 
         text-transform : uppercase;
       }
       .titre-content h1{
         font-size:1em;
         font-weight:normal;
       } 
     
