.container {
  padding-top: 70px;
  padding-bottom: 70px;
}
.home-job-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-job-card:hover{
  border: 1px solid #048402;

}


.home-job-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #011935;
}

.home-job-company, .home-job-location, .home-job-details {
  margin-bottom: 10px;
  color: #555;
}

.home-interest-button {
  background-color: #2e8b57;
  color: #fff;
  /* padding: 10px 20px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.home-interest-button:hover {
  background-color: #246a40;
}

.home-job-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: #777;
  align-items: center;
}

.home-job-date {
  font-size: 14px;
}
.home-job-type {
  color: #ff6347;
  font-weight: bold;
}
.card-button-bookmark-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px 0;
}


.card-new-apply-button {
  background-color: #2e8b57;
  color: #fff;
  /* padding: 10px 20px; */
  border-radius: 5px;
  font-size: 16px;
  flex: 0 0 30%;
  max-width: 80%;
  margin-right: 20px; /* Add margin to the right */
 
}
@media (max-width: 768px) {
  .card-button-bookmark-container {
    justify-content: center;
    flex-wrap: wrap;
  }
  .card-new-apply-button{
    max-width: 100%;
  }
}

.card-new-bookmark {
  font-size: 35px;
  color: #2e8b57;
  cursor: pointer;
}

.card-new-bookmark:hover {
  color: #4aab84;
}
.card-reject-icon {
  font-size: 36px;
  color: #ff0000;
  cursor: pointer;
  display: inline-block;
  margin-left: 18px;
  vertical-align: middle;
}

.card-reject-icon:hover {
  color: #ff4d4d;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style: none;
  padding: 0;
}

.pagination-link,
.pagination-page-link {
  display: inline-block;
  padding: 8px 12px; /* Adjust padding to ensure square shape */
  margin: 0 5px;
  cursor: pointer;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.pagination-link:hover,
.pagination-page-link:hover {
  background-color: #eee;
}

.pagination-active {
  color: #007bff;
  font-weight: bold;
}

.pagination-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.search-filters {
     
  justify-content: space-between;
  padding: 20px;
  background-color: #f2f2f2;
}

.filter-item {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-item label {
  font-weight: bold;
  margin-bottom: 5px;
}
.Job_count {
  text-align: center;
   }

   .filter-tags-c {
    background-color: white;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 5px;
    margin-top: -105px;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  @media (max-width: 767px) {
    .filter-tags-c {
      padding: 20px;
      margin-bottom: 50px;
    }
  }
  .filter-tags-c ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  .filter-tags-c ul li {
    display: flex;
    margin: 5px 15px 5px 0;
    border-radius: 4px;
    overflow: hidden;
  }
  .filter-tags-c ul li p {
    margin-right: 10px;
    margin-bottom: 0;
    padding: 7px;
    background-color: #f1f7f5;
    margin: 0;
    color: #58a9a7;
    font-weight: 500;
  }
  .filter-tags-c ul li span {
    background-color: #58a9a7;
    color: white;
    font-size: 25px;
    line-height: 1.3;
    padding: 0 5px;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .filter-tags-c ul li span:hover {
    background-color: black;
  }
  .filter-tags-c .clear-tags {
    color: #58a9a7;
    font-weight: 500;
    margin: 0;
    margin-left: 20px;
    border-bottom: 1px solid #58a9a7;
    cursor: pointer;
  }
