header {
  width: 100vw;
  height: 150px;
  background-color: #5da5a4;
}
header img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

main {
  /* background-color: #f0fafb; */
}

.container {
  padding-top: 70px;
  padding-bottom: 70px;
}

.job-card__details{
  text-align: center;
}

.job_id_info {
  padding: 5px 8px; /* Adjust padding values to make it smaller */
  background-color: #58a9a7;
  color: white;
  border-radius: 10px;
  font-size: 10px; /* Adjust font size to make it smaller */
  display: inline-block; /* Make it an inline element to reduce space */
  margin-bottom: 20px;
  
}
@media (max-width: 767px) {
  .job_id_info {
    display: block;
    margin-bottom: 20px;
    width: 30%;
  }
}

/* Add the following CSS */
.job_id_info:nth-child(2n) {
  transform: translateX(10px); /* Add a horizontal offset */
}

.job_id_info:nth-child(3n) {
  transform: translateY(-20px); /* Add a vertical offset */
}

.job-card {
  display: flex;
  padding: 30px;
  background-color: white;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 20px;
}

.job_card_subsection {
  width: calc(50% - 10px); /* Adjust the width as needed */
  margin-right: 10px; /* Adjust the margin as needed */
  
}

.job_card_sub_p p{
  flex-wrap: wrap;
  color: rgb(112, 112, 112) !important;
}
.job_card_sub_p{
  align-items: flex-start;
  flex-wrap: wrap;
}


@media (max-width: 767px) {
  .job-card {
    display: block;
    padding: 20px;
    margin-bottom: 50px;
  }
}
.job-card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.job-card p {
  font-size: 15px;
  color: #58a9a7;
  font-weight: 500;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .job-card__info {
    padding-bottom: 20px;
  }
}
.job-card__info a:hover {
  text-decoration: none;
}
.job-card__info .img-c {
  height: 88px;
  width: 88px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .job-card__info .img-c {
    height: 60px;
    width: 60px;
    margin: -50px 0 10px 0;
  }
  .job-card__info ul {
    flex-wrap: wrap;
  }
}
.job-card__info .img-c img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.job-card__info p {
  font-weight: 700;
  margin-bottom: 7px;
}
.job-card__info .tag-new {
  padding: 7px 10px;
  background-color: #58a9a7;
  color: white;
  border-radius: 20px;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 11px;
  display: none;
}
.job-card__info .tag-featured {
  padding: 7px 10px;
  background-color: black;
  color: white;
  border-radius: 20px;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 11px;
  display: none;
}
.job-card__info h6 {
  color: #323838;
  font-size: 18px;
  font-weight: 700;
}
.job-card__info ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.job-card__info ul li {
  font-size: 15px;
  color: #939c9b;
  padding: 0 10px;
  position: relative;
}
.job-card__info ul li:before {
  content: "";
  height: 4px;
  width: 4px;
  position: absolute;
  top: 50%;
  background-color: #939c9b;
  border-radius: 50%;
  left: -2px;
  transform: translateY(-50%);
}
.job-card__info ul li:first-child {
  padding-left: 0;
}
.job-card__info ul li:first-child:before {
  display: none;
}
.job-card__info ul li:last-child {
  padding-right: 0;
}
.job-card.new .tag-new {
  display: block;
}
.job-card.featured {
  border-left: 5px solid #58a9a7;
}
.job-card.featured .tag-featured {
  display: block;
}
.job-card__tags {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  list-style-type: none;
}
@media (max-width: 767px) {
  .job-card__tags {
    padding-top: 20px;
    padding-left: 0;
    border-top: 1px solid #939c9b;
  }
}
.job-card__tags li {
  margin-right: 10px;
  margin-bottom: 0;
  padding: 7px;
  border-radius: 4px;
  color: #58a9a7;
  font-weight: 500;
  background-color: #f1f7f5;
  margin: 5px 10px 5px 0;
  cursor: pointer;
  transition: all ease 0.2s;
}
.job-card__tags li:hover {
  background-color: #58a9a7;
  color: white;
}
.job-card__tags li:last-child {
  margin-right: 0;
}

.filter-tags-c {
  background-color: white;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 5px;
  margin-top: -105px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
@media (max-width: 767px) {
  .filter-tags-c {
    padding: 20px;
    margin-bottom: 50px;
  }
}
.filter-tags-c ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.filter-tags-c ul li {
  display: flex;
  margin: 5px 15px 5px 0;
  border-radius: 4px;
  overflow: hidden;
}
.filter-tags-c ul li p {
  margin-right: 10px;
  margin-bottom: 0;
  padding: 7px;
  background-color: #f1f7f5;
  margin: 0;
  color: #58a9a7;
  font-weight: 500;
}
.filter-tags-c ul li span {
  background-color: #58a9a7;
  color: white;
  font-size: 25px;
  line-height: 1.3;
  padding: 0 5px;
  cursor: pointer;
  transition: all ease 0.2s;
}
.filter-tags-c ul li span:hover {
  background-color: black;
}
.filter-tags-c .clear-tags {
  color: #58a9a7;
  font-weight: 500;
  margin: 0;
  margin-left: 20px;
  border-bottom: 1px solid #58a9a7;
  cursor: pointer;
}
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style: none;
  }
  
  .pagination-link {
    display: inline-block;
    padding: 8px;
    margin: 0 5px;
    cursor: pointer;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .pagination-link:hover {
    background-color: #eee;
  }
  
  .pagination-page-link {
    display: inline-block;
    padding: 8px;
    margin: 0 5px;
    cursor: pointer;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .pagination-page-link:hover {
    background-color: #eee;
  }
  
  .pagination-active {
    /* background-color: #007bff; */
    color: #007bff;
  }
  
  .pagination-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .my-input-group {
    margin-bottom: 20px;
  }
  
  .my-input-addon {
    padding: 8px;
    background-color: #e9ecef;
    border-color: #ced4da;
    color: #6c757d;
    height: 38px; /* Adjust the height of the addon */
  }
  
  .my-input {
    border-color: #ced4da;
    border-radius: 4px;
    box-shadow: none;
    height: 38px; /* Adjust the height of the input to match the addon */
  }
  
  .my-input:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .card-container {
    display: flex;
    justify-content: space-between;
  }
  
  .card23 {
    width: calc(50% - 10px); /* Adjust the width as needed */
    margin-right: 10px; /* Adjust the margin as needed */
    /* Other card styles */
    background-color: blue;
  }

  .container_grid {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: wrap ;
  }
  
  .box {
    width: calc(50% - 10px);
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .box:last-child {
    margin-right: 0; /* Remove right margin for the last box */
  }
  .box p:first-child {
    font-weight: bold;
  }
  .full-width {
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .full-width p{
    font-weight: bold;
  }
  .box-pair {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Adjust the margin as needed */
  }
  .pair-text {
    width: 100%;
    text-align: center;
    font-weight: bold;
    /* Additional styles as desired */
  }

  .status-badge {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 10px;
  }
  
  .pending {
    background-color: #F3C745;
    color: #FFF;
  }
  .new_applicant {
    background-color: #777;
    color: #FFF;
  }
  .status-date-container {
    display: flex;
    align-items: center;
  }

  
  .processing {
    background-color: #36B7F3;
    color: #FFF;
  }
  
  .success {
    background-color: #34C759;
    color: #FFF;
  }
  
  .error {
    background-color: #FF3B30;
    color: #FFF;
  }
  .status-date-container {
    display: flex;
    align-items: center;
  }
  .date {
    font-size: 14px;
    color: #777;
  }  

  .wwidth{
    min-width: 625px;
    }

    .ppadding{
      padding: 24px !important;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
    }
        

  
  
  